import classNames from 'classnames'
import { Colors } from 'colors'
import { UnderlinedH1 } from 'components/underlined-h1'
import React, { forwardRef } from 'react'
import { Trans } from 'react-i18next'
import style from './slide-faq.m.scss'

export const SlideFaq = forwardRef(({ className }, ref) => (
  <section ref={ref} className={classNames(style.root, className)}>
    <UnderlinedH1 underlineColor={Colors.primary} className={style.title}>
      <Trans i18nKey="faq.title"/>
    </UnderlinedH1>
    <h3>
      <Trans i18nKey="faq.question1"/>
    </h3>
    <p>
      <Trans i18nKey="faq.answer1"/>
    </p>
    <h3>
      <Trans i18nKey="faq.question2"/>
    </h3>
    <p>
      <Trans i18nKey="faq.answer2"/>
    </p>
    <h3>
      <Trans i18nKey="faq.question3"/>
    </h3>
    <p>
      <Trans i18nKey="faq.answer3"/>
    </p>
    <h3>
      <Trans i18nKey="faq.question4"/>
    </h3>
    <p>
      <Trans i18nKey="faq.answer4"/>
    </p>
    <h3>
      <p>
        <Trans i18nKey="faq.question5"/>
      </p>
    </h3>
    <p>
      <Trans i18nKey="faq.answer5.1"/>
    </p>
    <p>
      <Trans i18nKey="faq.answer5.2"/>
    </p>
    <h3>
      <Trans i18nKey="faq.question6"/>
    </h3>
    <p>
      <Trans i18nKey="faq.answer6.1"/>
    </p>
    <p>
      <Trans i18nKey="faq.answer6.2"/>
    </p>
    <p>
      <Trans i18nKey="faq.answer6.3"/>
    </p>
    <h3>
      <Trans i18nKey="faq.question7"/>
    </h3>
    <p>
      <Trans i18nKey="faq.answer7"/>
    </p>
    <h3>
      <Trans i18nKey="faq.question8"/>
    </h3>
    <p>
      <Trans i18nKey="faq.answer8"/>
    </p>
  </section>
))

SlideFaq.displayName = 'SlideFaq'
