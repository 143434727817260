import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import en from './en.json'
import { defer } from 'rxjs'

export const init = defer(() => (
  i18n
    .use(initReactI18next)
    .init({
      resources: {
        en: {
          translation: en
        }
      },
      fallbackLng: 'en',
      debug: true,
      react: {
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'wbr']
      },
      interpolation: {

        escapeValue: false
      }
    })
))
