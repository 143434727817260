import React from 'react'
import classNames from 'classnames'
import style from './mute-button.m.scss'

export const MuteButton = ({ className, muted, ...props }) => (
  <button
    className={classNames(style.root, { [style.muted]: muted }, className)}
    {...props}
  />
)
