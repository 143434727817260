import classNames from 'classnames'
import { FramedButton } from 'components/misc/misc'
import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import style from './read-more-button.m.scss'

export const ReadMoreButton = forwardRef(({ className, ...props }, ref) => {
  const { t } = useTranslation()

  return (
    <FramedButton
      ref={ref}
      className={classNames(style.root, className)}
      {...props}
    >
      {t('readMore')}
    </FramedButton>
  )
})
ReadMoreButton.displayName = 'ReadMoreButton'
