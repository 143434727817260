import { RootCtx } from 'contexts/root-context'
import React, { useEffect, useState } from 'react'
import { BackendService } from 'services/backend-service'
import { init } from './i18n'
import { environment } from './environment'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const RootWrapper = ({ children }) => {
  const [backendService] = useState(() => BackendService.of({
    baseUrl: environment.backendUrl,
    token: environment.backendToken
  }))

  const [initializing, setInitializing] = useState(true)

  useEffect(() => {
    const subscription = init.subscribe({
      next: () => { setInitializing(false) },
      error: error => { alert(error.message) }
    })

    return () => { subscription.unsubscribe() }
  }, [])

  if (initializing) return null

  return (
    <RootCtx.Provider value={{ backendService }}>
      {children}
      <ToastContainer
        draggable
        pauseOnHover={false}
      />
    </RootCtx.Provider>
  )
}
