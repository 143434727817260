// Breakpoints which must match those defined in styles.
export const PHONE = 0
export const MIN_PHONE = 320 // Design MOB
export const PHABLET = 480
export const TABLET = 768
export const SMALL_DESKTOP = 1024 // Design MID
export const DESKTOP = 1200
export const LARGE_DESKTOP = 1366 // Design HD
export const VERY_LARGE_DESKTOP = 1600
export const MAX_DESKTOP = 1920
