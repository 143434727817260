import classNames from 'classnames'
import { VideoPlayer } from 'components/video-player'
import { useScreenBreakpoint } from 'hooks/use-media'
import { SMALL_DESKTOP } from 'media-breakpoints'
import { EntryBoard } from './entry-board'
import React, { forwardRef } from 'react'
import style from './slide-intro.m.scss'
import { LinkToLanding } from 'pages/home/slide-intro/link-to-landing'

export const SlideIntro = forwardRef(({ className, visible, videoSrc }, ref) => {
  const isScreenDesktop = useScreenBreakpoint(SMALL_DESKTOP)

  return (
    <section ref={ref} className={classNames(style.root, className)}>
      <LinkToLanding/>
      <div className={style.videoAndOverlayContainer}>
        <VideoPlayer hasControls className={style.video} src={videoSrc}/>
        {
          isScreenDesktop && (
            <div className={classNames(style.overlay, { [style.hasAnimation]: visible })}>
              <EntryBoard className={style.overlayBoard} hasAnimation={visible}/>
            </div>
          )
        }
      </div>
      {!isScreenDesktop && <EntryBoard className={style.board}/>}
    </section>
  )
})

SlideIntro.displayName = 'SlideIntro'
