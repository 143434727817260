export class BackendService {
  static of (args) { return new BackendService(args) }

  constructor (args) {
    this.baseUrl = args.baseUrl
    this.headers = new Headers({
      'Authorization': `Bearer ${args.token}`,
      'Content-Type': 'application/json'
    })
  }

  registerPatient ({
    firstname,
    lastname,
    phone,
    email,
    location,
    natureOfInjury,
    quickOverview,
    howDidYouHearAboutUs,
    preferredMethodOfContact,
    bestTimeToCall
  }, provideAbort) {
    const body = JSON.stringify({
      fields: [
        {
          objectTypeId: '0-1',
          name: 'firstname',
          value: firstname
        },
        {
          objectTypeId: '0-1',
          name: 'lastname',
          value: lastname
        },
        {
          objectTypeId: '0-1',
          name: 'mobilephone',
          value: phone
        },
        {
          objectTypeId: '0-1',
          name: 'email',
          value: email
        },
        {
          objectTypeId: '0-1',
          name: 'location',
          value: location
        },
        {
          objectTypeId: '0-1',
          name: 'nature_of_injury__stroke_tbi_',
          value: natureOfInjury
        },
        {
          objectTypeId: '0-1',
          name: 'message',
          value: quickOverview
        },
        {
          objectTypeId: '0-1',
          name: 'how_did_you_hear_about_us_',
          value: howDidYouHearAboutUs
        },
        {
          objectTypeId: '0-1',
          name: 'preferred_method_of_contact',
          value: preferredMethodOfContact
        },
        {
          objectTypeId: '0-1',
          name: 'best_time_to_call',
          value: bestTimeToCall
        },
      ]
    })

    const controller = new AbortController()
    const signal = controller.signal

    provideAbort(() => controller.abort())

    return fetch(
      `${this.baseUrl}/7f32eddc-7d8a-4d1d-b003-4fd563f3bcb5`,
      {
        headers: this.headers,
        method: 'post',
        body,
        signal
      })
  }

  registerClinic ({
    fullName,
    phone,
    email,
    wereYouReferredBy,
    howDidYouHearAboutUs
  }, provideAbort) {

    const body = JSON.stringify({
      'fields': [
        {
          'objectTypeId': '0-1',
          'name': 'full_name',
          'value': fullName
        },
        {
          'objectTypeId': '0-1',
          'name': 'mobilephone',
          'value': phone
        },
        {
          'objectTypeId': '0-1',
          'name': 'email',
          'value': email
        },
        {
          'objectTypeId': '0-1',
          'name': 'were_you_referred_by_dr__chris_kager_',
          'value': wereYouReferredBy
        },
        {
          'objectTypeId': '0-1',
          'name': 'how_did_you_hear_about_us_',
          'value': howDidYouHearAboutUs
        }
      ]
    })

    const controller = new AbortController()
    const signal = controller.signal

    provideAbort(() => controller.abort())

    return fetch(
      `${this.baseUrl}/ca09387d-624f-4235-87fd-6e0c755422b0`,
      {
        headers: this.headers,
        method: 'post',
        body,
        signal
      }
    )
  }

  register ({
    name,
    phone,
    email,
    whatIsTheNatureOfYourInquiry,
    howDidYouHearAboutUs
  }, provideAbort) {

    const body = JSON.stringify({
      'fields': [
        {
          'objectTypeId': '0-1',
          'name': 'full_name',
          'value': name
        },
        {
          'objectTypeId': '0-1',
          'name': 'mobilephone',
          'value': phone
        },
        {
          'objectTypeId': '0-1',
          'name': 'email',
          'value': email
        },
        {
          'objectTypeId': '0-1',
          'name': 'what_is_the_nature_of_your_inquiry_',
          'value': whatIsTheNatureOfYourInquiry
        },
        {
          'objectTypeId': '0-1',
          'name': 'how_did_you_hear_about_us_',
          'value': howDidYouHearAboutUs
        }
      ]
    })

    const controller = new AbortController()
    const signal = controller.signal

    provideAbort(() => controller.abort())

    return fetch(
      `${this.baseUrl}/1a3bdc83-84cc-4c45-b40e-ce034a270e06`,
      {
        headers: this.headers,
        method: 'post',
        body,
        signal
      }
    )
  }
}
