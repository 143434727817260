import React from 'react'
import logo from 'resources/Torque3LogoTransResized.png'
import message from 'resources/message-stealth.png'
import stealth from 'resources/stealth-icon.png'
import { useTranslation } from 'react-i18next'
import style from './stealth-page.m.scss'

export const StealthPage = () => {
  const { t } = useTranslation()
  return <div className={style.root}>
    <img className={style.logoImg} src={logo}/>
    <span className={style.paragraphs}>{t('stealthPage.p1')}</span>
    <img className={style.stealthImage} src={stealth}/>
    <span className={style.paragraphs}>{t('stealthPage.p2')}</span>
    <span className={style.paragraphs}>{t('stealthPage.p3')}</span>
    <div>
      <a className={style.link} href="mailto:info@torque.com">
        <img className={style.messageImg} src={message}/>
        <span className={style.paragraphs}>{t('stealthPage.p4')}</span>
      </a>
    </div>
  </div>
}
