// Must match those defined in styles

export const Colors = {
  primary: 'rgb(255, 200, 10)',
  black: 'black',
  grayDark: 'rgb(80, 80, 80)',
  gray: 'rgb(175, 175, 175)',
  colorWhite: 'white',
  error: 'rgb(255, 120, 0)'
}
