import { useLayoutEffect, useState } from 'react'
import { PHABLET } from '../media-breakpoints'

export const useMedia = query => {
  const [matches, setMatches] = useState(false)

  useLayoutEffect(() => {
    const handleResize = () => {
      const matchesNewQuery = window.matchMedia(query).matches
      if (matchesNewQuery !== matches) { setMatches(matchesNewQuery) }
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [query, matches])

  return matches
}

export const useScreenBreakpoint = breakpoint => useMedia(`(min-width: ${breakpoint}px)`)

export const useOrientationLandscape = () => useMedia('(orientation: landscape)')

export const usePhoneLandscape = () => useMedia(`(max-height: ${PHABLET}px) and (orientation: landscape)`)
