import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import style from './site-link.m.scss'

export const SiteLink = ({ className, type, ...props }) => {
  const { t } = useTranslation()

  return (
    <a
      title={t(siteTypeToTranslation[type])}
      className={classNames(
        style.root,
        typeToClass[type],
        className
      )}
      {...props}
    />
  )
}

export const LinkType = {
  LINKED_IN: 'LINKED_IN',
  FACEBOOK: 'FACEBOOK',
  INSTAGRAM: 'INSTAGRAM',
  MEDIUM: 'MEDIUM',
  YOUTUBE: 'YOUTUBE',
  PAPERS: 'PAPERS',
  OTHER: 'OTHER'
}

const typeToClass = {
  [LinkType.LINKED_IN]: style.linkedIn,
  [LinkType.FACEBOOK]: style.facebook,
  [LinkType.INSTAGRAM]: style.instagram,
  [LinkType.MEDIUM]: style.medium,
  [LinkType.YOUTUBE]: style.youtube,
  [LinkType.PAPERS]: style.papers,
  [LinkType.OTHER]: style.other
}

const siteTypeToTranslation = {
  [LinkType.LINKED_IN]: 'linkedIn',
  [LinkType.FACEBOOK]: 'facebook',
  [LinkType.INSTAGRAM]: 'instagram',
  [LinkType.MEDIUM]: 'medium',
  [LinkType.YOUTUBE]: 'youtube',
  [LinkType.PAPERS]: 'papers',
  [LinkType.OTHER]: 'website'
}
