import classNames from 'classnames'
import { Colors } from 'colors'
import { Strapline } from 'components/misc/misc'
import { UnderlinedH1 } from 'components/underlined-h1'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import style from './entry-board.m.scss'
import { ListBullet } from './list-bullet'

export const EntryBoard = ({ hasAnimation = false, className }) => {
  const { t } = useTranslation()

  return (
    <div className={classNames(style.root, { [style.hasAnimation]: hasAnimation }, className)}>
      <div className={style.captionContainer}>
        <Strapline>{t('home.slideIntro.strapline')}</Strapline>
        <UnderlinedH1 className={style.caption} underlineColor={Colors.gray}>
          {t('home.slideIntro.title')}
        </UnderlinedH1>
      </div>
      <ul className={style.list}>
        <li className={style.listItem}>
          <ListBullet className={style.bullet}/>
          <span className={style.text}>
              <strong>{t('home.slideIntro.point1')}</strong>
            </span>
        </li>
        <li className={style.listItem}>
          <ListBullet className={style.bullet}/>
          <span className={style.text}>
              <Trans i18nKey={'home.slideIntro.point2'}>
                DEEPLY
                <strong>IMMERSIVE</strong>
              </Trans>
            </span>
        </li>
        <li className={style.listItem}>
          <ListBullet className={style.bullet}/>
          <span className={style.text}>
              <Trans i18nKey={'home.slideIntro.point3'}>
                TASK-ORIENTATED
                <strong>STROKE THERAPY</strong>
              </Trans>
            </span>
        </li>
      </ul>
    </div>
  )
}
