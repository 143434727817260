import classNames from 'classnames'
import React, { forwardRef } from 'react'
import { Link } from 'react-router-dom'
import style from './misc.m.scss'

/**
 * Small components which don't deserve their own files.
 */

/**
 * A small text before heading.
 */
export const Strapline = ({ children, className }) => (
  <small className={classNames(style.strapline, className)}>
    {children}
  </small>
)

export const FramedEmphasizedText = ({ className, children }) => (
  <section className={classNames(className, style.framedEmphasizedText)}>
    {children}
  </section>
)

export const FramedLink = ({ className, children, ...props }) => (
  <Link
    className={classNames(style.framedLink, className)}
    {...props}
  >
    {children}
  </Link>
)

export const FramedLinkExternal = ({ className, children, ...props }) => (
  <a
    className={classNames(style.framedLink, className)}
    {...props}
  >
    {children}
  </a>
)

export const FramedButton = forwardRef(({ className, children, ...props }, ref) => (
  <button
    ref={ref}
    className={classNames(style.framedLink, style.framedButton, className)}
    {...props}
  >
    {children}
  </button>
))
FramedButton.displayName = 'FramedButton'

export const BulletP = ({ className, children, indented = false, ...props }) => (
  <p
    className={classNames(
      style.bulletP,
      className,
      { [style.indented]: indented }
    )}
    {...props}
  >
    {children}
  </p>
)
