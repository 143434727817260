import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { environment } from '../../environment'
import { usePhoneLandscape } from 'hooks/use-media'
import { DetectedScreenSize } from '../detected-screen-size'
import { Header } from '../header'
import style from './layout.m.scss'

export const Layout = () => {
  const { pathname } = useLocation()

  const isPhoneLandscape = usePhoneLandscape()
  const hasHeader = pathname !== '/lab' && !isPhoneLandscape

  return <div className={style.root}>
{/*     {environment.development && <DetectedScreenSize className={style.screenSize}/>}
    {hasHeader && <Header className={style.header}/>} */}
    <Outlet/>
  </div>
}
