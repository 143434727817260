export const environment = {
  development: JSON.parse(process.env.T3_DEVELOPMENT),
  backendUrl: process.env.T3_BACKEND_URL,
  backendToken: process.env.T3_BACKEND_TOKEN,
}

if (environment.development) {
  console.debug('ENVIRONMENT')
  console.debug(JSON.stringify(environment, undefined, 4))
}
