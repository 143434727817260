import classNames from 'classnames'
import React, { forwardRef } from 'react'
import entryImage from 'resources/entry.jpg'
import style from './slide-entry.m.scss'

export const SlideEntry = forwardRef(({ className }, ref) => (
  <img width="100%" ref={ref} className={classNames(style.root, className)} src={entryImage}/>
))

SlideEntry.displayName = 'SlideEntry'
