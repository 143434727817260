import classNames from 'classnames'
import { Colors } from 'colors'
import React from 'react'
import style from './underlined-h1.m.scss'

export const UnderlinedH1 = ({ children, className, underlineColor = Colors.black }) => (
  <h1 className={classNames(style.root, className)}>
    {children}
    <div className={style.baselineArea}>
      <div className={style.underline} style={{ background: underlineColor }}/>
    </div>
  </h1>
)
