import classNames from 'classnames'
import { Colors } from 'colors'
import { FramedEmphasizedText } from 'components/misc/misc'
import { UnderlinedH1 } from 'components/underlined-h1'
import { VideoPlayer } from 'components/video-player'
import React, { forwardRef } from 'react'
import { Trans } from 'react-i18next'
import style from './slide-deeply-immersive-stroke-therapy.m.scss'

export const SlideDeeplyImmersiveStrokeTherapy = forwardRef(({
  className,
  visible,
  videoSrc
}, ref) => {
  return (
    <article ref={ref} className={classNames(style.root, { [style.visible]: visible }, className)}>
      <div className={style.title}>
        <UnderlinedH1 underlineColor={Colors.gray}>
          <Trans i18nKey="patients.slideDeeplyImmersiveStrokeTherapy.title"/>
        </UnderlinedH1>
      </div>
      <div className={style.textAndVideoContainer}>
        <div className={style.textContainer}>
          <p>
            <Trans i18nKey="patients.slideDeeplyImmersiveStrokeTherapy.paragraph1">
              Torque3 has developed a realistic, deeply immersive simulator for stroke and TBI
              rehabilitation that allows you to experience locomotion in a ‘real-life’
              simulation <strong>as if your body were not impaired.</strong> Why is this important?
              Imagine what it would feel like to be pedaling down a trail or dirt road, and your
              body is performing exactly as you would have expected BEFORE your stroke or brain
              injury – How would that make you feel?
            </Trans>
          </p>
          <p>
            <Trans i18nKey="patients.slideDeeplyImmersiveStrokeTherapy.paragraph2">
              What if – even for a 40-minute therapy session, <strong>you didn’t have to rely on a
              caretaker</strong> to help you get from one location to another – you could explore
              your environment on your own? What if YOU were the one who decided how fast you wanted
              to pedal, or even if you didn’t feel like pedaling at all, you just wanted to coast
              and take in the scenery?
            </Trans>
          </p>
          <p>
            <Trans i18nKey="patients.slideDeeplyImmersiveStrokeTherapy.paragraph3"/>
          </p>
          <p>
            <Trans i18nKey="patients.slideDeeplyImmersiveStrokeTherapy.paragraph4">
              Do you think if you could <strong>feel like a ‘whole person’ again</strong>, feel the
              freedom to make your own decisions, independent of a caretaker, and be shown measured
              incremental improvements based on actual data – that would motivate you to come to
              more rehabilitation therapy sessions and push even harder?
            </Trans>
          </p>
          <p>
            <Trans i18nKey="patients.slideDeeplyImmersiveStrokeTherapy.paragraph5">
              We hypothesize that it WOULD. Not only that, but we also believe that ‘Going to
              therapy’ would now become <strong>the best part of your day!</strong>
            </Trans>
          </p>
        </div>
        <div className={style.videoContainer}>
          <VideoPlayer
            className={style.video}
            videoAspectRatio={1}
            src={videoSrc}
          />
        </div>
      </div>
      <FramedEmphasizedText className={style.bottom}>
        <p>
          <Trans i18nKey="patients.slideDeeplyImmersiveStrokeTherapy.bottom">
            Our goal is to make ‘Going to therapy’ the <strong>best part</strong> of your day!
          </Trans>
        </p>
      </FramedEmphasizedText>
    </article>
  )
})

SlideDeeplyImmersiveStrokeTherapy.displayName = 'SlideTorqueVsTraditional'
