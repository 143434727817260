import { FramedLinkExternal } from 'components/misc/misc'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import style from './link-to-landing.m.scss'

export const LinkToLanding = () => {
  const { t } = useTranslation()

  return (
    <div className={style.root}>
      <div className={style.callout}>
        <h1><Trans i18nKey="experienceTheFutureNow"/></h1>
        <p><Trans i18nKey="torque3isSeekingVolunteers"/></p>
      </div>
      <svg viewBox="0 0 100 100">
        <polygon points="0,0 100,50 0,100"/>
      </svg>
      <FramedLinkExternal
        className={style.link}
        href="https://torque3.ac-page.com/FAE-application"
      >
        <h1>
          {t('learnMore')}
        </h1>
      </FramedLinkExternal>
    </div>
  )
}
