import classNames from 'classnames'
import { FramedEmphasizedText } from 'components/misc/misc'
import React, { forwardRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { VideoPlayer } from '../../../components/video-player'
import style from './slide-clinic-owner.m.scss'

export const SlideClinicOwner = forwardRef(({ className, visible, videoSrc }, ref) => {
  const { t } = useTranslation()

  return (
    <section ref={ref} className={classNames(style.root, { [style.visible]: visible }, className)}>
      <article>
        <h1>{t('clinics.slideClinicOwner.title')}</h1>
        <div className={style.body}>
          <div className={style.videoContainer}>
            <VideoPlayer className={style.video} src={videoSrc}/>
          </div>
          <div className={style.text}>
            <p>
              <Trans i18nKey="clinics.slideClinicOwner.paragraph1">
                You care about providing the best possible care to your patients and helping them
                improve – and you’re also running a business where you’re thinking about paying your
                rent, staff, and keeping the lights on. Our goal is to utilize the high level of
                automation of the Torque3 stroke rehab platform to provide not only <strong>the best
                possible results for stroke treatment but also help you increase your
                profits.</strong>
              </Trans>
            </p>
            <p>
              <Trans i18nKey="clinics.slideClinicOwner.paragraph2">
                In most cases, independently owned clinics should be able to <strong>double (if not
                triple!) the number of patients they can serve</strong> without any increase in
                staffing or expansion of floor space.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="clinics.slideClinicOwner.paragraph3">
                As you can imagine, progress in stroke recovery treatment may seem subjective. Is
                the patient getting stronger? Or are stroke treatment and recovery practitioners
                falsely inflating the results due to their optimistic projections?
              </Trans>
            </p>
            <p>
              <Trans i18nKey="clinics.slideClinicOwner.paragraph4">
                With the Torque3 stroke rehabilitation platform, you don’t have to question your
                perceptions because our platform <strong>accurately reports stroke recovery
                progress.</strong> Improvements in strength, stamina, and precision are recorded.
                This not only provides proof of the efficacy of our stroke recovery and
                rehabilitation platform but also gives critical motivation to patients to keep
                working to improve.
              </Trans>
            </p>
          </div>
        </div>
      </article>
      <FramedEmphasizedText className={style.bottom}>
        <p>
          <Trans i18nKey="clinics.slideClinicOwner.bottomParagraph">
            Provide the <strong>most advanced rehabilitation technology</strong> to care for your
            patients AND <strong>increase profits!</strong>
          </Trans>
        </p>
      </FramedEmphasizedText>
    </section>
  )
})

SlideClinicOwner.displayName = 'SlideClinicOwner'
