import classNames from 'classnames'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import style from './t3-form-field.m.scss'

export const T3FormField = ({
  className,
  invalid = false,
  required,
  children,
  ...props
}) => {
  const { t } = useTranslation()

  const text = useMemo(() => {
    if (required === true) return t('required')
    if (required === false) return t('optional')
  }, [t, required])

  return (
    <div
      className={classNames(style.root, { [style.invalid]: invalid }, className)}
      {...props}
    >
      {children}
      {text !== undefined && <small>{text}</small>}
    </div>
  )
}
