import Player from '@vimeo/player'
import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { MuteButton } from './mute-button/mute-button'
import style from './video-player.m.scss'
import { useResizeDetector } from 'react-resize-detector'

export const VideoPlayer = ({ className, src, hasControls = false, videoAspectRatio = 16 / 9 }) => {
  const [state, setState] = useState()
  const [switchMutedRequest, setSwitchMutedRequest] = useState()

  const { width, height, ref } = useResizeDetector()

  const videoSize = useMemo(() => {
    if (width === undefined || height === undefined) return undefined

    const containerAspectRatio = width / height

    if (containerAspectRatio >= videoAspectRatio) {
      // container is wider

      const videoWidth = '100%'
      const videoHeight = `${100 * containerAspectRatio / videoAspectRatio}%`

      return {
        width: videoWidth,
        height: videoHeight
      }
    } else {
      // video is wider

      const videoHeight = '100%'
      const videoWidth = `${100 * videoAspectRatio / containerAspectRatio}%`

      return {
        width: videoWidth,
        height: videoHeight
      }
    }
  }, [width, height, videoAspectRatio])

  // Switch muted state when requested
  useEffect(() => {
    if (switchMutedRequest === undefined || state === undefined) return

    const player = state.player

    player
      .getMuted()
      .then(muted => {
        player
          .setMuted(!muted)
          .then(() => { player.play() })
      })
  }, [switchMutedRequest, state?.player])

  // Listen for events when player is set
  useEffect(() => {
    if (state === undefined) return

    const player = state.player

    player.on('volumechange', () => {
      player.getMuted().then(muted => { setState(old => ({ ...old, muted })) })
    })

    return () => { player.off('volumechange') }
  }, [state?.player])

  const onFrameMounted = useCallback(element => {
    if (element === null) return

    const player = new Player(element)

    player
      .getMuted()
      .then(muted => {
        setState({ player, muted })
      })
  }, [])

  return (
    <div
      ref={ref}
      className={classNames(style.root, className)}
    >
      {hasControls && state !== undefined && (
        <MuteButton
          className={style.muteButton}
          muted={state.muted}
          onClick={() => {
            setSwitchMutedRequest({})
          }}
        />
      )}
      <iframe
        ref={onFrameMounted}
        src={src}
        frameBorder="0"
        style={{
          minHeight: videoSize?.height ?? '100%',
          minWidth: videoSize?.width ?? '100%'
        }}
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      />
    </div>
  )
}
