import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { Layout } from './components/layout'
import { ROOT } from './element-ids'
import { AboutPage } from './pages/about'
import { ClinicsPage } from './pages/clinics'
import { ContactPage } from './pages/contact'
import { FaqPage } from './pages/faq'
import { HomePage } from './pages/home'
import { PatientsPage } from './pages/patients'
import { RezStorPage } from './pages/rezstor'
import {
  SlideContent,
  SlideContent1,
  SlideContent2,
  SlideContent3,
  SlideContent4,
  SlideContent5
} from './pages/rezstor/slide-content'
import { RootWrapper } from './root-wrapper'
import './style/global.scss'
import { StealthPage } from 'pages/stealth-page/stealth-page'

ReactDOM.render(
  <RootWrapper>
    <HashRouter>
      <Routes>
        <Route path="/" element={<Layout/>}>
          <Route index element={<StealthPage/>}/>
         {/*  <Route index element={<HomePage/>}/>
          <Route path="about" element={<AboutPage/>}/>
          <Route path="stroke-survivors" element={<PatientsPage/>}/>
          <Route path="clinics" element={<ClinicsPage/>}/>
          <Route path="contact" element={<ContactPage/>}/>
          <Route path="lab" element={<RezStorPage/>}>
            <Route index element={<SlideContent/>}/>
            <Route path="utah-b" element={<SlideContent1/>}/>
            <Route path="survivor" element={<SlideContent2/>}/>
            <Route path="tbi" element={<SlideContent3/>}/>
            <Route path="caregivers" element={<SlideContent4/>}/>
            <Route path="utah-a" element={<SlideContent5/>}/>
          </Route>
          <Route path="faq" element={<FaqPage/>}/> */}
        </Route>
      </Routes>
    </HashRouter>
  </RootWrapper>,
  document.getElementById(ROOT)
)
