import classNames from 'classnames'
import React, { forwardRef, useLayoutEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import style from './overlay.m.scss'

export const Overlay = forwardRef(({
  children, className, parentProvider, isVisible = false,   isSemitransparent = false, ...props
}, ref) => {
  const [element] = useState(() => document.createElement('div'))
  element.className = classNames(
    style.root,
    { [style.isVisible]: isVisible, },
  )

  useLayoutEffect(() => {
    const parent = parentProvider()
    parent.appendChild(element)
    return () => { parent.removeChild(element) }
  })

  return ReactDOM.createPortal(
    <div
      ref={ref}
      className={classNames(
        style.overlay,
        { [style.isSemitransparent]: isSemitransparent },
        className
      )}
      {...props}
    >
      {children}
    </div>,
    element
  )
})

Overlay.displayName = 'Overlay'
